<template>
  <div class="packge">
    <template v-if="loading">
      <div class="primary-packge">
        <img class="cover" :src="cover" alt="" />
        <div class="title">
          <h1 style="white-space: pre-wrap">{{ packge.bookName }}</h1>
        </div>
        <template v-if="isDown">
          <a
            target="_blank"
            :href="packge.filePath"
            class="down"
            @click="allDown"
            >整书资源下载</a
          >
        </template>
        <template v-else>
          <p class="mxy-noDown">
            <em v-if="packge.bookSlogan">{{ packge.bookSlogan }}</em>
            <em v-else>资源制作、上传中，敬请期待</em>
          </p>
        </template>
        <div class="info">
          <p>下载打印攻略：</p>
          <p>1.点击上方的“整书资源下载”，将文件下载到手机端浏览；</p>
          <p>2.将文件链接分享到QQ的“我的电脑”或微信的“文件传输助手”；</p>
        </div>
      </div>
    </template>
    <template v-else>
      <template v-if="loadingExce">
        <div class="primary-nothing">
          <img class="cover" src="../assets/images/nothing.png" alt="" />
          <p class="title">您查找的内容不存在</p>
          <router-link
            :to="{
              name: 'propose',
              query: { bid: bid, rid: rid, source: source },
            }"
            class="btn"
          >
            <img class="icon" src="../assets/images/ope.png" alt="" />我要反馈
          </router-link>
        </div>
      </template>
      <template v-else>
        <div class="primary-folder-no">
          <van-loading>{{ loadingText }}</van-loading>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { Dialog } from "vant";

export default {
  name: "packge",
  data() {
    return {
      loading: false,
      loadingText: "资源加载中...",
      loadingExce: false,
      rid: this.$route.query.rid,
      bid: this.$route.query.bid,
      packge: "",
      cover: this.$store.state.iCdn + "2021XXNewBook/images/nobook.jpg",
      isDown: false,
      source: 4,
    };
  },
  mounted() {
    // this.rid = '450976709315923968';
    // this.bid = '450976065297321984';

    //判断参数是否有值
    if (
      this.rid != undefined &&
      this.rid != "" &&
      this.bid != undefined &&
      this.bid != ""
    ) {
      this.getBookPackge();
    } else {
      this.loading = false;
      this.loadingExce = true;
    }
  },
  methods: {
    //下载记录
    allDown() {
      let params = {
        rid: this.rid,
        bid: this.bid,
      };
      this.$api.mary.DownBookPackge(params).then((res) => {
        let { tag, message, data } = res;
        if (tag == 1) {
        } else {
          Dialog.alert({
            message: message,
          });
        }
      });
    },

    //获取整体资源
    getBookPackge() {
      this.loading = false;
      let params = {
        rid: this.rid,
        bid: this.bid,
      };
      this.$api.mary.GetBookPackge(params).then((res) => {
        let { tag, Message, data } = res;
        if (tag == 1) {
          this.packge = data;
          document.title = this.packge.bookName;
          if (this.packge.resCoverImg != "") {
            this.cover = this.packge.resCoverImg;
          } else if (this.packge.bookCoverImg != "") {
            this.cover = this.packge.bookCoverImg;
          }
          if (this.packge.filePath != "") {
            this.isDown = true;
          }
          this.loading = true;
          this.loadingExce = false;
        } else {
          this.loadingExce = true;
        }
        console.log(res);
      });
    },
  },
};
</script>
<style scoped>
.packge {
  height: 100%;
}
</style>
